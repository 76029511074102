import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询设备类型列表
export function listDeviceType(query: any) {
  return request({
    url: "/factory/deviceType/list",
    method: "get",
    params: query,
  });
}

// 查询设备类型详细
export function getDeviceType(query: any) {
  return request({
    url: "/factory/deviceType/" + query.id,
    method: "get",
    // params: query,
  });
}

// 新增设备类型
export function addDeviceType(data: any) {
  return request({
    url: "/factory/deviceType",
    method: "post",
    data: data,
  });
}

// 修改设备类型
export function updateDeviceType(data: any) {
  return request({
    url: "/factory/deviceType",
    method: "put",
    data: data,
  });
}

// 删除设备类型
export function delDeviceType(data: any) {
  return request({
    url: "/factory/deviceType/removes",
    method: "delete",
    data: data,
  });
}
// 修改设备状态
export function updateStatus(data: any) {
  return request({
    url: "/factory/deviceType/status",
    method: "put",
    data: data,
  });
}
